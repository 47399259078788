<template>
  <svg width="32" height="26" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 26">
    <g clip-path="url(#a)">
      <path d="M31.499 4.87c-.166-1.116-.352-1.66-.847-2.44-.486-.768-1.296-1.463-2.373-1.71C26.206.241 22.231 0 16 0S5.794.242 3.721.719c-1.076.247-1.887.943-2.373 1.71C.854 3.21.667 3.754.502 4.87.198 6.92 0 9.22 0 13c0 3.78.198 6.081.501 8.13.166 1.116.352 1.66.847 2.44.486.768 1.296 1.463 2.373 1.71 2.072.477 6.047.72 12.278.72 6.232 0 10.207-.243 12.279-.72 1.077-.247 1.887-.942 2.373-1.71.495-.78.682-1.324.847-2.44.303-2.05.501-4.35.501-8.13 0-3.78-.198-6.081-.501-8.13Zm-18.6 13.681V7.449L21.313 13 12.9 18.551Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h32v26H0z"/>
      </clipPath>
    </defs>
  </svg>
</template>